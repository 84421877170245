<template>
  <div class="card shadow">
    <div class="card-body">
      <h4 class="card-title">Intérêts composés</h4>

      <div class="card-text">
        <form class="row g-3">
          <div class="col-md-6">
            <label for="inputInitialInvestment" class="form-label">Investissement initial</label>
            <input
              type="text"
              class="form-control"
              id="inputInitialInvestment"
              placeholder="10000"
              v-model="investment.initialInvestment"
            />
          </div>
          <div class="col-md-6">
            <label for="inputAnnualInterestRate" class="form-label"
              >Taux d'intérêt annuel (%)
              <small
                class="fw-lighter fst-italic"
                v-if="!isNaN(parseInt(72 / investment.annualInterestRate))"
                >Votre montant pourrait doubler tous les
                <span class="fw-bold">{{ parseInt(72 / investment.annualInterestRate) }}</span>
                an(s)</small
              ></label
            >
            <small class="fw-lighter fst-italic" v-show="false">TSX YTD : {{ tsx_ytd }}</small>
            <input
              type="text"
              class="form-control"
              id="inputAnnualInterestRate"
              placeholder="10"
              v-model="investment.annualInterestRate"
            />
          </div>
          <div class="col-12">
            <label for="inputInvestmentPeriod" class="form-label"
              >Période d'investissement (années)</label
            >
            <input
              type="text"
              class="form-control"
              id="inputInvestmentPeriod"
              placeholder="10"
              v-model="investment.investmentPeriod"
            />
          </div>
          <div class="col-12">
            <label for="inputCompoundInterval" class="form-label">Calcul des intérêts</label>
            <select
              id="inputCompoundInterval"
              class="form-select"
              v-model="investment.compoundInterval"
              disabled
            >
              <option value="daily">Journalier</option>
              <option value="monthly" selected>Mensuel</option>
              <option value="yearly">Annuel</option>
            </select>
          </div>
          <div class="col-md-8">
            <label for="inputRegularInvestment" class="form-label">Fréquence des versements</label>
            <select
              id="inputRegularInvestment"
              class="form-select"
              v-model="investment.regularInvestment"
            >
              <option value="weekly">Une fois par semaine</option>
              <option value="bimonthly">Deux fois par mois</option>
              <option value="monthly" selected>Une fois par mois</option>
              <option value="quartely">Une fois par trimestre</option>
              <option value="yearly">Une fois par année</option>
            </select>
          </div>
          <div class="col-md-4">
            <label for="inputInvestmentValue" class="form-label"
              >Montant des versements
              <small
                class="fw-lighter fst-italic"
                v-if="
                  !isNaN(parseInt(investment.investmentValue)) &&
                  parseInt(investment.investmentValue) > 0
                "
                >(+<span class="fw-bold">{{
                  format(
                    parseFloat(investment.investmentValue * period[investment.regularInvestment]),
                  )
                }}</span>
                par année)</small
              ></label
            >
            <input
              type="text"
              class="form-control"
              id="inputInvestmentValue"
              v-model="investment.investmentValue"
            />
          </div>
          <div class="col-12">
            <button type="submit" class="btn btn-primary" @click.prevent="compute">Calculer</button>
          </div>
        </form>

        <hr />

        <div class="wl-result" v-if="computed_result">
          <p>
            Valeure total du placement: <span class="fw-bold">{{ computed_result.total }}</span>
          </p>
          <p>
            Intérêts gagnés: <span class="fw-bold">{{ computed_result.interestEarned }}</span>
          </p>
          <p>
            Montant investi: <span class="fw-bold">{{ computed_result.investment }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Form',

  data() {
    return {
      investment: {
        initialInvestment: 10000,
        annualInterestRate: 7,
        investmentPeriod: 25,
        compoundInterval: 'monthly',
        regularInvestment: 'monthly',
        investmentValue: 0,
      },

      result: {
        total: 0,
        interestEarned: 0,
        totalInvested: 0,
      },

      computed_result: null,

      period: {
        weekly: 52,
        bimonthly: 26,
        monthly: 12,
        quartely: 4,
        yearly: 1,
      },

      tsx_ytd: 'N/A',
    };
  },

  methods: {
    compute() {
      const INTERVAL = 12; // interests calculated every months
      const initialInvestment = parseFloat(this.investment.initialInvestment).toFixed(2);
      const annualInterestRate = parseFloat(this.investment.annualInterestRate) / 100;
      const period = parseFloat(this.investment.investmentPeriod).toFixed(2);
      const regularInvestmentValue = parseFloat(this.investment.investmentValue).toFixed(2);

      if (annualInterestRate <= 0 || period <= 0 || regularInvestmentValue < 0) {
        console.error(annualInterestRate, period, regularInvestmentValue);
        return;
      }
      const principal =
        initialInvestment * (1 + annualInterestRate / INTERVAL) ** (INTERVAL * period);

      const series =
        ((regularInvestmentValue * this.period[this.investment.regularInvestment || 'monthly']) /
          INTERVAL) *
        (((1 + annualInterestRate / INTERVAL) ** (INTERVAL * period) - 1) /
          (annualInterestRate / INTERVAL));

      const total = parseFloat(principal + series).toFixed(2);
      const investment = (
        parseFloat(initialInvestment) +
        parseFloat(
          regularInvestmentValue *
            this.period[this.investment.regularInvestment || 'monthly'] *
            period,
        )
      ).toFixed(2);

      const interestEarned = (total - investment).toFixed(2);

      this.computed_result = {
        principal,
        series,
        interestEarned: this.format(interestEarned),
        total: this.format(total),
        investment: this.format(investment),
      };
    },

    format(number) {
      const formatter = new Intl.NumberFormat(window.navigator.language || navigator.language, {
        style: 'currency',
        currency: 'CAD',
      });

      if (Number.isNaN(number)) {
        return formatter.format(0);
      }

      return formatter.format(number);
    },
  },
};
</script>
