<template>
  <div class="row justify-content-center align-items-center border p-2 rounded-top text-center">
    <div class="col-md-2 col-12 wl-groups">
      <p class="lead">{{ sections.groups.i18n[lang] }}</p>
    </div>

    <div class="col-md-10 wl-entries">
      <div class="row justify-content-center align-items-center">
        <div class="col-4">
          <p class="lead">{{ sections.categories.i18n[lang] }}</p>
        </div>
        <div class="col-8 wl-simulations">
          <div class="row justify-content-center align-items-center">
            <div class="col" v-for="(simulation, key) of sections.simulations" :key="key">
              <p class="lead">{{ simulation.i18n[lang] }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',

  props: {
    lang: { type: String, default: 'fr' },
    sections: { type: Object },
  },
};
</script>
