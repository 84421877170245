<template>
  <div class="row justify-content-center align-items-center border p-2 wl-section">
    <div class="col-md-2 col-12 wl-groups">
      <h4>{{ section.i18n[lang] }}</h4>
    </div>

    <div class="col-md-10 wl-entries">
      <div class="row mb-2 align-items-center">
        <div class="col-12 col-md-8 offset-md-4 wl-values">
          <div class="row">
            <div class="col" v-for="col of simulations" :key="col">
              <div class="revenues input-group">
                <input
                  @change="updateValue"
                  :id="'input-' + col"
                  type="text"
                  class="form-control position-relative"
                  :class="{
                    'bg-danger': section.fn
                      ? caller(section.fn)[col - 1] < 0 && section.bg === true
                      : false,
                    'bg-success': section.fn
                      ? caller(section.fn)[col - 1] > 0 && section.bg === true
                      : false,
                  }"
                  placeholder="0$"
                  :disabled="section.ro"
                  :value="
                    section.fn
                      ? caller(section.fn)[col - 1]
                      : initialValues.filter((iv) => iv.key === 'input-' + col)[0]
                      ? initialValues.filter((iv) => iv.key === 'input-' + col)[0].value
                      : null
                  "
                  v-on:refresh="section.fn ? caller(section.fn)[col - 1] : null"
                />
                <span
                  v-if="col !== 1 && diff(col) && section.badge && !isNaN(diff(col))"
                  class="expenses difference position-absolute top-0 start-100 translate-middle badge rounded-pill"
                  :class="{ 'bg-danger': diff(col) >= 0, 'bg-success': diff(col) <= 0 }"
                >
                  {{ diff(col) }}%
                  <span class="visually-hidden">{{
                    diff(col) > 0 ? 'Increased' : 'Decreased'
                  }}</span>
                </span>
                <div class="input-group-text d-none d-sm-none d-md-block hide-print">$</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',

  props: {
    section: { type: Object },
    lang: { type: String, default: 'fr' },
    updateValue: { type: Function },
    getTotal: { type: Function },
    getDifference: { type: Function },
    simulations: { type: Number, default: 3 },
    initialValues: { type: Array },
  },

  methods: {
    caller(fnName) {
      return this[fnName]();
    },

    diff(col) {
      let symbol = '+';
      let val = 0;
      if (!this.getTotal()[0]) {
        return null;
      }

      if (parseInt(this.getTotal()[0], 10) > parseInt(this.getTotal()[col - 1], 10)) {
        symbol = '-';
      }

      if (!parseInt(this.getTotal()[col - 1], 10)) {
        return null;
      }

      const newNumber = parseInt(this.getTotal()[col - 1], 10);
      const originalNumber = parseInt(this.getTotal()[0], 10);
      if (originalNumber === 0 || !originalNumber) {
        return null;
      }

      if (symbol === '+') {
        const increase = newNumber - originalNumber;
        val = (increase / originalNumber) * 100;
      } else {
        const decrease = originalNumber - newNumber;
        val = (decrease / originalNumber) * 100;
      }

      return symbol + val.toFixed(0);
    },
  },
};
</script>
