<template>
  <div class="row justify-content-center align-items-center border p-2 wl-sections">
    <div class="col-md-2 col-12 wl-groups text-center">
      <h4>{{ section.i18n[lang] }}</h4>
    </div>

    <div class="col-md-10 wl-entries">
      <div class="row">
        <div class="col" v-for="col of 3" :key="col">
          <div class="input-group">
            <input
              v-if="section.input_type === 'input'"
              @change="updateValue"
              :id="'input-' + col + '-' + sectionId"
              type="text"
              class="form-control form-control-sm"
              :value="
                initialValues.filter((iv) => iv.key === 'input-' + col + '-' + sectionId)[0]
                  ? initialValues.filter((iv) => iv.key === 'input-' + col + '-' + sectionId)[0]
                      .value
                  : null
              "
            />
            <input
              v-if="section.input_type === 'boolean'"
              @change="updateValue"
              :id="'input-' + col + '-' + sectionId"
              type="radio"
              :name="'input-' + sectionId"
              class="form-check-input"
              :checked="
                initialValues.filter((iv) => iv.key === 'input-' + col + '-' + sectionId)[0]
                  ? initialValues.filter((iv) => iv.key === 'input-' + col + '-' + sectionId)[0]
                      .value
                  : null
              "
              :value="
                initialValues.filter((iv) => iv.key === 'input-' + col + '-' + sectionId)[0]
                  ? initialValues.filter((iv) => iv.key === 'input-' + col + '-' + sectionId)[0]
                      .value
                  : null
              "
            />

            <input
              v-if="section.input_type === 'number'"
              @change="updateValue"
              :id="'input-' + col + '-' + sectionId"
              type="number"
              class="form-control form-control-sm"
              :min="section.min"
              :max="section.max"
              :value="
                initialValues.filter((iv) => iv.key === 'input-' + col + '-' + sectionId)[0]
                  ? initialValues.filter((iv) => iv.key === 'input-' + col + '-' + sectionId)[0]
                      .value
                  : null
              "
            />

            <textarea
              v-if="section.input_type === 'textarea'"
              @change="updateValue"
              :id="'input-' + col + '-' + sectionId"
              class="form-control"
              :value="
                initialValues.filter((iv) => iv.key === 'input-' + col + '-' + sectionId)[0]
                  ? initialValues.filter((iv) => iv.key === 'input-' + col + '-' + sectionId)[0]
                      .value
                  : null
              "
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreparationSection',

  props: {
    sectionId: { type: String },
    section: { type: Object },
    lang: { type: String, default: 'fr' },
    updateValue: { type: Function },
    initialValues: { type: Array },
  },
};
</script>
