<template>
  <div class="row justify-content-center align-items-center border p-2 wl-section">
    <div class="col-md-2 col-12 wl-groups">
      <h4>{{ section.i18n[lang] }}</h4>
    </div>

    <div class="col-md-10 wl-entries">
      <div class="row mb-2 align-items-center">
        <div class="col-12 col-md-8 offset-md-4 wl-values">
          <div class="row">
            <div class="col" v-for="col of simulations" :key="col">
              <div class="input-group">
                <input
                  :id="'input-' + col"
                  type="text"
                  class="form-control position-relative"
                  :class="{
                    'bg-danger': section.fn ? caller(section.fn)[col - 1] < 0 && section.bg === true : false,
                    'bg-success': section.fn ? caller(section.fn)[col - 1] > 0 && section.bg === true : false,
                  }"
                  :value="section.fn ? caller(section.fn)[col - 1] : null"
                  placeholder="0$"
                  :disabled="section.ro"
                />
                <span
                  v-if="
                    col !== 1 &&
                    section.type !== 'none' &&
                    diff(col, section.type) &&
                    section.badge &&
                    !isNaN(diff(col, section.type))
                  "
                  class="position-absolute top-0 start-100 translate-middle badge rounded-pill"
                  :class="{
                    'bg-success':
                      (section.type === 'assets' && diff(col, section.type) >= 0) ||
                      (section.type === 'liabilities' && diff(col, section.type) <= 0),
                    'bg-danger':
                      (section.type === 'assets' && diff(col, section.type) <= 0) ||
                      (section.type === 'liabilities' && diff(col, section.type) >= 0),
                  }"
                >
                  {{ diff(col, section.type) }}%
                  <span class="visually-hidden">{{ diff(col, section.type) > 0 ? 'Increased' : 'Decreased' }}</span>
                </span>
                <div class="input-group-text d-none d-sm-none d-md-block hide-print">$</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',

  props: {
    section: { type: Object },
    lang: { type: String, default: 'fr' },
    getAssetsTotal: { type: Function },
    getLiabilitiesTotal: { type: Function },
    getDifference: { type: Function },
    simulations: { type: Number, default: 3 },
  },

  methods: {
    caller(fnName) {
      return this[fnName]();
    },

    diff(col, type) {
      if (type !== 'assets' && type !== 'liabilities') {
        console.error(`Invalid type: ${type}`);
        return null;
      }

      let symbol = '+';
      let val = 0;
      const newTotal = type === 'assets' ? this.getAssetsTotal()[col - 1] : this.getLiabilitiesTotal()[col - 1];
      const currTotal = type === 'assets' ? this.getAssetsTotal()[0] : this.getLiabilitiesTotal()[0];

      if (!currTotal) {
        return null;
      }
      if (!parseInt(newTotal, 10)) {
        return null;
      }

      if (parseInt(currTotal, 10) > parseInt(newTotal, 10)) {
        symbol = '-';
      }
      const originalNumber = parseInt(currTotal, 10);
      const newNumber = parseInt(newTotal, 10);

      if (originalNumber === 0 || !originalNumber) {
        return null;
      }

      if (symbol === '+') {
        const increase = newNumber - originalNumber;
        val = (increase / originalNumber) * 100;
      } else {
        const decrease = originalNumber - newNumber;
        val = (decrease / originalNumber) * 100;
      }

      return symbol + val.toFixed(0);
    },
  },
};
</script>
