<template>
  <div class="cookies-section d-flex align-items-center justify-content-center" v-if="show">
    <div class="cookies">
      <div class="row">
        <div class="col-md-10 col-8">
          <span class="lead align-middle">Ce site utilise les cookies</span>
        </div>
        <div class="col-md-2 col-4">
          <button class="btn btn-primary me-auto" @click="accept">D'accord</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cookies',

  data() {
    return {
      show: true,
    };
  },

  mounted() {
    this.showMessage();
  },

  methods: {
    accept() {
      window.localStorage.setItem('cookiesAccepted', true);
      this.show = false;
    },

    showMessage() {
      const status = window.localStorage.getItem('cookiesAccepted');
      if (status && status === 'true') {
        this.show = false;
      } else {
        this.show = true;
      }

      return status;
    },
  },
};
</script>

<style>
.cookies-section {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.cookies {
  width: 60%;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #292929;
  z-index: 120;
  cursor: pointer;
  border-radius: 10px 10px 0px 0px;
}

@media screen and (max-width: 600px) {
  .cookies {
    width: 100%;
  }
}
</style>
