<template>
  <div class="container shadow mb-5 mt-2 pt-3">
    <div class="card m-3 shadow hide-print">
      <div class="card-body">
        <p>
          Vous pouvez entrer vos informations pour comparer vos budgets, cet outil est pratique pour
          savoir rapidement si votre projet est possible.
        </p>
        <p class="small">
          Tout le contenu est seulement sauvegardé sur votre machine locale. Nous ne collectons
          aucune donnée.
        </p>
      </div>
    </div>

    <div id="actions" class="mb-2 hide-print">
      <button class="btn btn-danger" @click="reset">Réinitialiser</button>
      <button class="btn btn-primary ms-3 me-3" @click="print()">Imprimer</button>
      <Share :share="share" />
    </div>

    <h1 class="text-center">Mon Budget</h1>

    <Header
      :sections="headerSections"
      :updateDescription="updateDescription"
      :descriptions="descriptions"
    />

    <Section
      v-for="(section, key) in sections"
      :key="key"
      :section="section"
      :updateValue="updateValue"
      :simulations="simulations"
      :initialValues="values"
      :getTotal="sumPerSimulation"
      :sectionId="key"
      :getRevenues="revenues"
    />

    <hr />

    <div class="wl-footer">
      <Footer
        v-for="(section, key) in footerSections"
        :key="key"
        :section="section"
        :updateValue="updateRevenue"
        :simulations="simulations"
        :getTotal="sumPerSimulation"
        :getDifference="getDifference"
        :initialValues="revenues"
      />
    </div>
  </div>

  <div class="toast-container position-fixed top-0 end-0 p-3">
    <div
      ref="toastMsg"
      class="toast align-items-center bg-success"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="d-flex">
        <div class="toast-body text-white">{{ message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import { evaluate } from 'mathjs';
import { Toast } from 'bootstrap';
import Section from '../components/Budget/Section.vue';
import Header from '../components/Budget/Header.vue';
import Footer from '../components/Budget/Footer.vue';
import Share from '../components/Share/Button.vue';
import data from '../assets/data.json';

export default {
  name: 'Budget',
  components: {
    Section,
    Header,
    Footer,
    Share,
  },

  setup() {
    useMeta({
      title: 'Budget',
      htmlAttrs: {
        lang: 'fr',
        amp: true,
      },
    });
  },

  data() {
    return {
      sections: data.budget.sections,
      footerSections: data.budget.footer,
      headerSections: data.budget.header,
      values: [],
      descriptions: [],
      revenues: [],
      simulations: 3,
      message: 'Yeah !',
      toast: null,
    };
  },

  created() {
    this.initRevenues();
  },

  async mounted() {
    if (localStorage.budget) {
      console.debug('Loading data from local storage');
      const budget = JSON.parse(localStorage.budget);
      this.values = budget.values;
      this.descriptions = budget.descriptions;
      this.revenues = budget.revenues;
      this.simulations = budget.simulations;
    }

    if (this.$route.query.data) {
      this.loadFromSharedLink();
    }
    this.initToasts();
    this.updateRevenue();
  },

  watch: {
    '$route.query.data': {
      handler(val) {
        if (val) {
          this.loadFromSharedLink(val);
        }
      },
      immediate: true,
    },
  },

  methods: {
    loadFromSharedLink(val = null) {
      console.debug('Loading data from shared link');
      try {
        const shared = JSON.parse(decodeURIComponent(atob(val || this.$route.query.data)));
        this.values = shared.values;
        this.descriptions = shared.descriptions;
        this.revenues = shared.revenues;
        this.simulations = shared.simulations;
        this.message = 'Budget chargé à partir du lien partagé avec succès !';
        this.toast.show();
      } catch (e) {
        localStorage.removeItem('budget');
      }
    },

    share() {
      navigator.clipboard.writeText(
        `${window.location.protocol}//${window.location.host}/budget?data=${btoa(
          encodeURIComponent(localStorage.budget),
        )}`,
      );
      this.message = 'Lien généré avec succès dans votre clipboard !';
      this.toast.show();
    },

    initToasts() {
      console.debug('Initialize Toasts');
      this.toast = new Toast(this.$refs.toastMsg, { animation: true, autohide: true, delay: 2000 });
    },

    print() {
      window.print();
    },

    initRevenues() {
      [...Array(this.simulations)].forEach((_, idx) =>
        this.revenues.push({ key: `input-${idx + 1}`, value: null }),
      );
    },

    reset() {
      localStorage.removeItem('budget');
      this.sections = data.budget.sections;
      this.footerSections = data.budget.footer;
      this.values = [];
      this.descriptions = [];
      this.revenues = [];
      this.simulations = 3;

      this.initRevenues();
      this.message = 'Réinitialisé avec succès !';
      this.toast.show();
    },

    saveLocally() {
      localStorage.budget = JSON.stringify({
        values: this.values,
        descriptions: this.descriptions,
        revenues: this.revenues,
        simulations: this.simulations,
      });
      this.message = 'Sauvegardé localement avec succès !';
      this.toast.show();
    },

    updateValue(ev) {
      let val = ev.target.value || 0;

      if (val === null || val === '' || val === undefined) {
        val = null;
      }

      if (val) {
        val = evaluate(val);
      }

      const idx = this.values.findIndex((value) => value.key === ev.target.id);
      if (idx !== -1) {
        this.values[idx].value = val ? val.toFixed(2) : null;
      } else {
        this.values.push({ key: ev.target.id, value: val.toFixed(2) });
      }
      this.$emit('refresh');
      this.saveLocally();
    },

    updateRevenue(ev) {
      if (!ev) {
        return null;
      }
      let val = ev?.target?.value || null;
      if (val === null || val === '' || val === undefined) {
        val = null;
        return null;
      }
      if (val) {
        val = evaluate(val);
      }

      this.revenues.find((revenue) => revenue.key === ev?.target?.id).value = val
        ? val.toFixed(2)
        : null;
      this.$emit('refresh');
      this.saveLocally();
      return true;
    },

    sumPerSimulation() {
      if (!this.values || this.values.length <= 0) return [];

      return [...Array(this.simulations)].map((_, key) =>
        this.values
          .filter((value) => value.key.match(new RegExp(`input-.*-${key + 1}`)))
          .reduce(
            (acc, curr) =>
              (
                parseFloat(acc) +
                (curr.value && !Number.isNaN(curr.value) ? parseFloat(curr.value) : 0)
              ).toFixed(2),
            0,
          ),
      );
    },

    getDifference() {
      const spending = [...Array(this.simulations)].map((_, key) =>
        this.values
          .filter((value) => value.key.match(new RegExp(`input-.*-${key + 1}`)))
          .reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.value || 0), 0),
      );

      return [
        ...spending.map((sim, idx) =>
          (parseFloat(this.revenues[idx].value || 0) - parseFloat(sim || 0)).toFixed(2),
        ),
      ];
    },

    updateDescription(ev) {
      let val = ev.target.value || null;

      if (val === null || val === '' || val === undefined) {
        val = null;
      }

      const idx = this.descriptions.findIndex((value) => value.key === ev.target.id);
      if (idx !== -1) {
        this.descriptions[idx].value = val;
      } else {
        this.descriptions.push({ key: ev.target.id, value: val });
      }
      this.$emit('refresh');
      this.saveLocally();
    },
  },
};
</script>
