<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }} - Ma Future Maison</template>
  </metainfo>

  <nav class="navbar navbar-expand-lg navbar-light bg-light hide-print">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">Ma Future Maison</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/budget">Budget</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/net-worth">Avoir net</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/compound-interests">Intérêts composés</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/preparation">Préparation</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div>
    <router-view />
    <footer class="text-center mt-3 mb-5 lead">
      <Cookies />
      <p>
        Création <a class="" href="https://www.webuxlab.com" target="_blank">Webux Lab</a> @ 2021 -
        2022
      </p>
    </footer>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import Cookies from './components/Cookies/Cookies.vue';

export default {
  name: 'App',

  components: {
    Cookies,
  },

  setup() {
    useMeta({
      title: 'Accueil',
      htmlAttrs: {
        lang: 'fr',
        amp: true,
      },
    });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

@media print {
  body {
    margin: 0;
    color: #000;
    background-color: #fff;
  }

  .hide-print {
    display: none;
    visibility: hidden;
    width: 100%;
  }

  input {
    font-size: 8px;
  }

  .wl-footer {
    break-before: always;
  }

  h4 {
    font-size: 15px !important;
  }

  p {
    font-size: 8px;
  }
}
</style>
