<template>
  <div class="row justify-content-center align-items-center border p-2 rounded-top text-center">
    <div class="col-md-12 wl-entries">
      <div class="row justify-content-center align-items-center">
        <div class="offset-2 col-10 wl-categories">
          <div class="row justify-content-center align-items-center">
            <div class="col" v-for="(category, key) of sections.categories" :key="key">
              <p class="lead">{{ category.i18n[lang] }}</p>
              <form class="form" @submit.prevent="updateDescription">
                <input
                  type="text"
                  name="description"
                  class="form-control form-control-sm"
                  :id="'description-' + key"
                  placeholder="Description"
                  :value="
                    descriptions.filter((iv) => iv.key === 'description-' + key)[0]
                      ? descriptions.filter((iv) => iv.key === 'description-' + key)[0].value
                      : null
                  "
                  @change="updateDescription"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreparationHeader',

  props: {
    lang: { type: String, default: 'fr' },
    sections: { type: Object },
    descriptions: { type: Array },
    updateDescription: { type: Function },
  },
};
</script>
