<template>
  <div class="row justify-content-center align-items-center border p-2 wl-sections">
    <div class="col-md-2 col-12 wl-groups text-center">
      <h4>{{ section.i18n[lang] }}</h4>
    </div>

    <div class="col-md-10 wl-entries">
      <div class="row mb-2 align-items-center wl-sub-categories" v-for="(sub, key) of section['sub-categories']" :key="key">
        <div class="col-4">
          <p :id="'p-' + key" class="text-start text-wrap">
            {{ sub.i18n[lang] }} ({{ sub.type === 'assets' ? '+' : '-' }})
          </p>
        </div>
        <div class="col-8 wl-values">
          <div class="row">
            <div class="col" v-for="col of simulations" :key="col">
              <div class="input-group">
                <input
                  @change="updateValue"
                  :id="'input-' + sub.type + '-' + col + '-' + group + '-' + key"
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="0$"
                  :value="
                    initialValues.filter(
                      (iv) => iv.key === 'input-' + sub.type + '-' + col + '-' + group + '-' + key,
                    )[0]
                      ? initialValues.filter(
                          (iv) => iv.key === 'input-' + sub.type + '-' + col + '-' + group + '-' + key,
                        )[0].value
                      : null
                  "
                />
                <div class="input-group-text d-sm-none d-none d-md-block hide-print">$</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Section',

  props: {
    section: { type: Object },
    group: { type: String },
    lang: { type: String, default: 'fr' },
    updateValue: { type: Function },
    simulations: { type: Number, default: 3 },
    initialValues: { type: Array },
  },
};
</script>
