<template>
  <div class="container shadow mb-5 mt-2 pt-3">
    <div class="card m-3 shadow hide-print">
      <div class="card-body">
        <p>Vous pouvez entrer vos informations pour suivre la progression de votre avoir net.</p>
        <p class="small">
          Tout le contenu est seulement sauvegardé sur votre machine locale. Nous ne collectons aucune donnée.
        </p>
      </div>
    </div>

    <div id="actions" class="mb-2 hide-print">
      <button class="btn btn-danger" @click="reset">Réinitialiser</button>
      <button class="btn btn-primary ms-3 me-3" @click="print()">Imprimer</button>
      <Share :share="share" />
    </div>

    <h1 class="text-center">Mon Avoir Net</h1>

    <Header :sections="headerSections" />

    <Section
      v-for="(section, key) in sections"
      :key="key"
      :section="section"
      :group="key"
      :updateValue="updateValue"
      :simulations="simulations"
      :initialValues="values"
    />

    <hr />

    <div class="wl-footer">
      <Footer
        v-for="(section, key) in footerSections"
        :key="key"
        :section="section"
        :simulations="simulations"
        :getAssetsTotal="assetsSumPerSimulation"
        :getLiabilitiesTotal="liabilitiesSumPerSimulation"
        :getDifference="getDifference"
      />
    </div>
  </div>

  <div class="toast-container position-fixed top-0 end-0 p-3">
    <div
      ref="toastMsg"
      class="toast align-items-center bg-success"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="d-flex">
        <div class="toast-body text-white">{{ message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import { evaluate } from 'mathjs';
import { Toast } from 'bootstrap';
import Section from '../components/NetWorth/Section.vue';
import Header from '../components/NetWorth/Header.vue';
import Footer from '../components/NetWorth/Footer.vue';
import Share from '../components/Share/Button.vue';
import data from '../assets/data.json';

export default {
  name: 'NetWorth',
  components: {
    Section,
    Header,
    Footer,
    Share,
  },

  setup() {
    useMeta({
      title: 'Avoir Net',
      htmlAttrs: {
        lang: 'fr',
        amp: true,
      },
    });
  },

  data() {
    return {
      sections: data['net-worth'].sections,
      footerSections: data['net-worth'].footer,
      headerSections: data['net-worth'].header,
      values: [],
      simulations: 3,
      message: 'Yeah !',
      toast: null,
    };
  },

  async mounted() {
    if (localStorage.networth) {
      console.debug('Loading data from local storage');
      const networth = JSON.parse(localStorage.networth);
      this.values = networth.values;
      this.simulations = networth.simulations;
    }

    if (this.$route.query.data) {
      this.loadFromSharedLink();
    }
    this.initToasts();
  },

  watch: {
    '$route.query.data': {
      handler(val) {
        if (val) {
          this.loadFromSharedLink(val);
        }
      },
      immediate: true,
    },
  },

  methods: {
    loadFromSharedLink(val = null) {
      console.debug('Loading data from shared link');
      try {
        const shared = JSON.parse(decodeURIComponent(atob(val || this.$route.query.data)));
        this.values = shared.values;
        this.simulations = shared.simulations;
        this.message = 'networth chargé à partir du lien partagé avec succès !';
        this.toast.show();
      } catch (e) {
        localStorage.removeItem('networth');
      }
    },

    share() {
      navigator.clipboard.writeText(
        `${window.location.protocol}//${window.location.host}/net-worth?data=${btoa(
          encodeURIComponent(localStorage.networth),
        )}`,
      );
      this.message = 'Lien généré avec succès dans votre clipboard !';
      this.toast.show();
    },

    initToasts() {
      console.debug('Initialize Toasts');
      this.toast = new Toast(this.$refs.toastMsg, { animation: true, autohide: true, delay: 2000 });
    },

    print() {
      window.print();
    },

    reset() {
      localStorage.removeItem('networth');
      this.sections = data['net-worth'].sections;
      this.footerSections = data['net-worth'].footer;
      this.headerSections = data['net-worth'].header;
      this.values = [];
      this.simulations = 3;

      this.message = 'Réinitialisé avec succès !';
      this.toast.show();
    },

    saveLocally() {
      localStorage.networth = JSON.stringify({
        values: this.values,
        simulations: this.simulations,
      });
      this.message = 'Sauvegardé localement avec succès !';
      this.toast.show();
    },

    updateValue(ev) {
      let val = ev.target.value || 0;

      if (val === null || val === '' || val === undefined) {
        val = null;
      }

      if (val) {
        val = evaluate(val);
      }

      const idx = this.values.findIndex((value) => value.key === ev.target.id);
      if (idx !== -1) {
        this.values[idx].value = val ? val.toFixed(2) : null;
      } else {
        this.values.push({ key: ev.target.id, value: val.toFixed(2) });
      }
      this.$emit('refresh');
      this.saveLocally();
    },

    assetsSumPerSimulation() {
      if (!this.values || this.values.length <= 0) return [];
      return [...Array(this.simulations)].map((_, key) =>
        this.values
          .filter((value) => value.key.includes(`input-assets-${key + 1}`))
          .reduce(
            (acc, curr) =>
              (parseFloat(acc) + (curr.value && !Number.isNaN(curr.value) ? parseFloat(curr.value) : 0)).toFixed(2),
            0,
          ),
      );
    },

    liabilitiesSumPerSimulation() {
      return [...Array(this.simulations)].map((_, key) =>
        this.values
          .filter((value) => value.key.includes(`input-liabilities-${key + 1}`))
          .reduce(
            (acc, curr) =>
              (parseFloat(acc) + (curr.value && !Number.isNaN(curr.value) ? parseFloat(curr.value) : 0)).toFixed(2),
            0,
          ),
      );
    },

    getDifference() {
      return [...Array(this.simulations)].map((_, key) =>
        (
          parseFloat(this.assetsSumPerSimulation()[key] || 0) - parseFloat(this.liabilitiesSumPerSimulation()[key] || 0)
        ).toFixed(2),
      );
    },
  },
};
</script>
