<template>
  <button class="btn btn-primary" @click="share">Share</button>
</template>

<script>
export default {
  name: 'Share',

  props: {
    share: { type: Function },
  },
};
</script>
