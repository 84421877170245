<template>
  <div class="container mt-3">
    <Form />
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import Form from '../components/FutureValue/Form.vue';

export default {
  name: 'CompoundInterest',

  components: {
    Form,
  },

  setup() {
    useMeta({
      title: 'Intérêt composés',
      htmlAttrs: {
        lang: 'fr',
        amp: true,
      },
    });
  },
};
</script>
