<template>
  <div class="row justify-content-center align-items-center border p-2 wl-sections">
    <div class="col-md-2 col-12 wl-groups text-center">
      <h4>{{ section.i18n[lang] }}</h4>

      <div class="percent">
        <span class="fw-bold fst-italic" v-for="(total, idx) in getTotal()" :key="idx">
          <span v-if="total > 0"> {{ getPercent(sectionId, idx) }}% </span>
          <span v-else>0%</span
          ><span class="fw-lighter fst-normal" v-if="idx < getTotal().length - 1"
            >&nbsp;/&nbsp;</span
          ></span
        >
      </div>
    </div>

    <div class="col-md-10 wl-entries">
      <div
        class="row mb-2 align-items-center wl-sub-categories"
        v-for="(sub, key) of section['sub-categories']"
        :key="key"
      >
        <div class="col-4">
          <p :id="'p-' + key" class="text-start text-wrap">{{ sub.i18n[lang] }}</p>
        </div>
        <div class="col-8 wl-values">
          <div class="row">
            <div class="col" v-for="col of simulations" :key="col">
              <div class="input-group">
                <input
                  @change="updateValue"
                  :id="'input-' + sectionId + '-' + col + '-' + key"
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="0$"
                  :value="
                    initialValues.filter(
                      (iv) => iv.key === 'input-' + sectionId + '-' + col + '-' + key,
                    )[0]
                      ? initialValues.filter(
                          (iv) => iv.key === 'input-' + sectionId + '-' + col + '-' + key,
                        )[0].value
                      : null
                  "
                />
                <div class="input-group-text d-sm-none d-none d-md-block hide-print">$</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Section',

  props: {
    sectionId: { type: String },
    section: { type: Object },
    lang: { type: String, default: 'fr' },
    updateValue: { type: Function },
    simulations: { type: Number, default: 3 },
    initialValues: { type: Array },
    getTotal: { type: Number },
    getRevenues: { type: Array },
  },

  methods: {
    getTotalPerCategory(category, idx) {
      return this.initialValues
        .filter((iv) => iv.key.match(new RegExp(`${category}-${idx}`)))
        .reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.value || 0), 0);
    },

    getPercent(sectionId, idx) {
      if (this.getTotalPerCategory(sectionId, idx + 1) <= 0 || this.getRevenues[idx].value <= 0) {
        return 0;
      }

      return (
        (this.getTotalPerCategory(sectionId, idx + 1) / this.getRevenues[idx].value) *
        100
      ).toFixed(0);
    },
  },
};
</script>
