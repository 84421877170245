<template>
  <div class="container shadow mb-5 mt-2 pt-3">
    <div class="card m-3 shadow hide-print">
      <div class="card-body">
        <p>
          Vous pouvez entrer vos informations pour vous préparer à l'achat, cet outil est pratique
          pour savoir rapidement si votre projet est possible.
        </p>
        <p class="small">
          Tout le contenu est seulement sauvegardé sur votre machine locale. Nous ne collectons
          aucune donnée.
        </p>
      </div>
    </div>

    <div id="actions" class="mb-2 hide-print">
      <button class="btn btn-danger" @click="reset">Réinitialiser</button>
      <button class="btn btn-primary ms-3 me-3" @click="print()">Imprimer</button>
      <Share :share="share" />
    </div>

    <h1 class="text-center">Préparation</h1>

    <Header
      :sections="headerSections"
      :updateDescription="updateDescription"
      :descriptions="descriptions"
    />

    <Section
      v-for="(line, key) in lines"
      :key="key"
      :sectionId="key"
      :section="line"
      :updateValue="updateValue"
      :initialValues="values"
    />

    <hr />
  </div>

  <div class="toast-container position-fixed top-0 end-0 p-3">
    <div
      ref="toastMsg"
      class="toast align-items-center bg-success"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="d-flex">
        <div class="toast-body text-white">{{ message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import { boolean, evaluate } from 'mathjs';
import { Toast } from 'bootstrap';
import Section from '../components/Preparation/Section.vue';
import Header from '../components/Preparation/Header.vue';
import Share from '../components/Share/Button.vue';
import data from '../assets/data.json';

export default {
  name: 'Preparation',
  components: {
    Section,
    Header,
    Share,
  },

  setup() {
    useMeta({
      title: 'Préparation',
      htmlAttrs: {
        lang: 'fr',
        amp: true,
      },
    });
  },

  data() {
    return {
      lines: data.preparation.lines,
      headerSections: data.preparation.header,
      values: [],
      descriptions: [],
      message: 'Yeah !',
      toast: null,
    };
  },

  async mounted() {
    if (localStorage.preparation) {
      console.debug('Loading data from local storage');
      const preparation = JSON.parse(localStorage.preparation);
      this.values = preparation.values || [];
      this.descriptions = preparation.descriptions;
    }

    if (this.$route.query.data) {
      this.loadFromSharedLink();
    }
    this.initToasts();
  },

  watch: {
    '$route.query.data': {
      handler(val) {
        if (val) {
          this.loadFromSharedLink(val);
        }
      },
      immediate: true,
    },
  },

  methods: {
    loadFromSharedLink(val = null) {
      console.debug('Loading data from shared link');
      try {
        const shared = JSON.parse(decodeURIComponent(atob(val || this.$route.query.data)));
        this.values = shared.values || [];
        this.descriptions = shared.descriptions;
        this.message = 'Préparation chargé à partir du lien partagé avec succès !';
        this.toast.show();
      } catch (e) {
        localStorage.removeItem('preparation');
      }
    },

    share() {
      navigator.clipboard.writeText(
        `${window.location.protocol}//${window.location.host}/preparation?data=${btoa(
          encodeURIComponent(localStorage.preparation),
        )}`,
      );
      this.message = 'Lien généré avec succès dans votre clipboard !';
      this.toast.show();
    },

    initToasts() {
      console.debug('Initialize Toasts');
      this.toast = new Toast(this.$refs.toastMsg, { animation: true, autohide: true, delay: 2000 });
    },

    print() {
      window.print();
    },

    reset() {
      localStorage.removeItem('preparation');
      this.sections = data.preparation.sections;
      this.values = [];
      this.descriptions = [];

      this.message = 'Réinitialisé avec succès !';
      this.toast.show();
    },

    saveLocally() {
      localStorage.preparation = JSON.stringify({
        values: this.values,
        descriptions: this.descriptions,
      });
      this.message = 'Sauvegardé localement avec succès !';
      this.toast.show();
    },

    updateValue(ev) {
      let val = ev.target.value;
      console.log(ev.target.value, ev.target.id);

      if (val === null || val === '' || val === undefined) {
        val = null;
      }

      console.log('val', val);

      if (val && !Number.isNaN(parseFloat(val))) {
        val = evaluate(val);
      }

      if (val && (val === 'on' || val === 'off' || val === 'true' || val === 'false')) {
        val = val === 'on';
        this.values = [
          ...this.values.map((value) => {
            console.debug('debug', ev.target.id.split('-'));
            if (value.key.includes(ev.target.id.split('-')[2])) {
              // eslint-disable-next-line no-param-reassign
              value.value = false;
            }
            return value;
          }),
        ];
      }

      const idx = this.values.findIndex((value) => value.key === ev.target.id);
      if (idx !== -1) {
        let t = null;
        if (val !== null) {
          if (!Number.isNaN(parseFloat(val))) {
            t = val.toFixed(0);
          } else if (val === true || val === false) {
            t = !boolean(val);
          } else {
            t = val;
          }
        }
        this.values[idx].value = t;
      } else {
        this.values.push({
          key: ev.target.id,
          value: val && !Number.isNaN(parseFloat(val)) ? val.toFixed(0) : val,
        });
      }
      this.$emit('refresh');
      this.saveLocally();
    },

    updateDescription(ev) {
      let val = ev.target.value || null;

      if (val === null || val === '' || val === undefined) {
        val = null;
      }

      const idx = this.descriptions.findIndex((value) => value.key === ev.target.id);
      if (idx !== -1) {
        this.descriptions[idx].value = val;
      } else {
        this.descriptions.push({ key: ev.target.id, value: val });
      }
      this.$emit('refresh');
      this.saveLocally();
    },
  },
};
</script>
