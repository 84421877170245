import { createRouter, createWebHistory } from 'vue-router';

import Budget from '../views/Budget.vue';
import Preparation from '../views/Preparation.vue';
import NotFound from '../views/NotFound.vue';
import NetWorth from '../views/NetWorth.vue';
import CompoundInterest from '../views/CompoundInterest.vue';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),

  routes: [
    {
      path: '/compound-interests',
      component: CompoundInterest,
    },

    {
      path: '/net-worth',
      component: NetWorth,
    },

    {
      path: '/budget',
      component: Budget,
    },

    {
      path: '/preparation',
      component: Preparation,
    },

    {
      path: '/',
      component: Budget,
    },

    {
      path: '/:pathMatch(.*)*',
      component: NotFound,
      beforeEnter() {
        window.location = `/404.html`;
      },
    },
  ],
});

export default router;
